import React, { useState, useEffect } from 'react';

const Countdown: React.FC = () => {
  const targetDate = new Date('2023-06-30T23:59:59');
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function calculateTimeRemaining() {
    const currentTime = new Date();
    const difference = targetDate.getTime() - currentTime.getTime();

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  return (
    <div>
      <h1>Resultado será divulgado em:</h1>
      <p>
        {timeRemaining.hours} horas,{' '}
        {timeRemaining.minutes} minutos, {timeRemaining.seconds} segundos
      </p>
    </div>
  );
};

export default Countdown;
