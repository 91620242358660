import { Navigate } from "react-router-dom";
import { useIsAuth } from "../hooks/useAuth"

export function RequireAuth({ children }:{children:JSX.Element}){

    if(!useIsAuth()){
        return <Navigate to="/login" state={{ from: '/' }} replace />;
    }
    return children

}