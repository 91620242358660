
import logoImg from '../assets/img/logo.png'
import { Button } from '../componentes/Button'
import '../styles/home.scss'
import { Candidata } from '../componentes/Candidata'
import { useNavigate } from 'react-router-dom'
import { FooterCandidataHome } from '../componentes/FooterCandidataHome'
import { useCandidatas } from '../hooks/useCandidatas'
import { Header } from '../componentes/Header'

export function Home(){
    const history = useNavigate()
    const { candidatas , votos } = useCandidatas()
    
    function logout(){
        localStorage.clear()
        history('/login')
    }
    
    return(
        <div id="page-room">
            <Header />
            {false && (

                <main className='content'>
                    <div className='room-title'>
                        <h1>Escolha sua candidata:</h1>
                    </div>
                    <div className='question-list'>
                        <div className='list-flex'>
                            {candidatas.map(candidata => {
                                return(
                                    <Candidata key={candidata.id}
                                        content={candidata}>
                                            <FooterCandidataHome candidata={candidata} />
                                    </Candidata>
                                )
                            })}
                        </div>
                    </div>
                    
                </main>
            )}
            <main className='content'>
                    <div className='room-title'>
                        Acompanhe o Resultado Final do Concurso
                    </div>
                    
            </main>
        </div>
    )
}