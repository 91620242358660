
import logoIMG from '../assets/img/logo.png'
import '../styles/login.scss'
import { Button } from '../componentes/Button'
import { Navigate, useNavigate} from 'react-router-dom'
import { FormEvent, useEffect, useState } from 'react'
import  api  from '../services/api'
import { useIsAuth } from '../hooks/useAuth'



export function Login(){

    
    
    const history = useNavigate()
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [soujurado, setSoujurado] = useState('showsoujurado')
    const [exibeLogin, setExibeLogin] = useState('hiddenlogin')

    async function handleLogin(event:FormEvent){
        event.preventDefault()
        
        const response = await api.post('user/login', new URLSearchParams({
            email: login,
            password: senha
        }))
        .then((response) => {
            localStorage.setItem("token",response.data.token)
            history('/')
        })
        .catch((e) =>{
            if (e.response) {
                // Is this the correct way?
                alert(e.response?.data.error)
            }
            
        })
    }

    async function showLogin(event:FormEvent){
        event.preventDefault()
        setSoujurado('hiddensoujurado')
        setExibeLogin('showlogin')
        
    }

    async function handleCompra(event:FormEvent){
        event.preventDefault()
        history('/cadastro')
    }

    if(useIsAuth()){
        return <Navigate to="/" state={{ from: '/login' }} replace />;
    }

    return(
        <div id="page-auth">
            <aside>
                <strong>Concurso do Cool</strong>
                <p>Faça Login para escolher o cool mais bonito do Brasil</p>
            </aside>
            <main>
                <div className="main-content">
                    <img src={logoIMG} alt="Logo" />
                    <button onClick={handleCompra} className="create-room-google">
                        Me tornar jurado
                    </button>
                    <div className={exibeLogin}>
                        <div className="separator">Já tem Login?</div>
                        <form onSubmit={handleLogin}>
                            <input 
                                type="text"
                                placeholder='Login'
                                onChange={event => setLogin(event.target.value)}
                                value={login}
                            />
                            <input 
                                type="password"
                                placeholder='Senha'
                                onChange={event => setSenha(event.target.value)}
                                value={senha}
                            />
                            <Button>Entrar</Button>
                        </form>
                    </div>
                    <div className={soujurado}>
                        <Button onClick={showLogin}>Já sou jurado</Button>
                    </div>
                </div>
            </main>
        </div>
    )
}

