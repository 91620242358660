import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../services/api"

export type CandidataType = {
    id: string
    nome: string
    cidade: string
    foto: string
    topfan: string
}

export function useCandidatas() {
    const history = useNavigate()
    const [candidatas, setCandidatas] = useState<CandidataType[]>([])
    const [votos, setVotos] = useState(0)

    useEffect(() => {
        
        const apiCandidatas = api.get('candidatas/listCandidatas')
        .then((response) => {
            if(response){
                setCandidatas(response.data.dados)
                setVotos(response.data.votos)
            }else{
                localStorage.clear()
                alert("Sessão Expirada")
                history('/login')
            }
        })
        .catch((e) =>{
            if (e.response) {
                localStorage.clear()
                alert("Sessão Expirada, faça Login novamente")
                history('/login')
            }
            
        })
    }, [])
    return {candidatas, votos}

}