import { useEffect } from "react";
import { Navigate,useNavigate } from "react-router-dom";
import { useIsAuth } from "../hooks/useAuth"
import api from "../services/api"

export function RequireAuthVote({ children }:{children:JSX.Element}){
    const history = useNavigate()
    useEffect(() => {

        const carregaDados = async () =>{
            const apiCandidatas = api.get('candidatas/listCandidatas')
            .then((response) => {
                if(response){
                    if(response.data.votos==0){
                        console.log('Zero')
                        history('/compra')
                    }
                }
            })
            .catch((e) =>{
                localStorage.clear()
                history('/login')
            }) 
            
            
        }
        carregaDados()

    }, [])

    if(!useIsAuth()){
        return <Navigate to="/login" state={{ from: '/' }} replace />;
    }
    
    return children   

}