import "../styles/header.scss"
import { useNavigate } from 'react-router-dom'
import logoImg from '../assets/img/logo.png'
import { useCandidatas } from '../hooks/useCandidatas'



export function Header(){
    const history = useNavigate()
    const { votos } = useCandidatas()

    function logout(){
        localStorage.clear()
        history('/login')
    }

    function compra(){
        history('/compra')
    }

    function home(){
        history('/')
    }

    function resultado(){
        history('/resultado')
    }

    return(
        <header>
            <div className="content-header">
                <div>
                    <img onClick={home} src={logoImg} alt="Logo"/>
                </div>
                <div className="actions">
                    <div onClick={home} className='button marginbutton'>
                        <span>Votar</span>
                    </div>
                    <div onClick={resultado} className='button marginbutton'>
                        <span>Resultados</span>
                    </div>
                    <div onClick={compra} className='compra-chance'>
                        <span>Comprar Votos: {votos}</span>
                    </div>
                    <div onClick={logout} className='button marginbutton'>
                        <span>Sair</span>
                    </div>
                </div>
                
            </div>
        </header>
            
    )
}