import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import api from "../services/api";

export type UserType = {
    id: string
    name: string
    email: string
}



export function useUserAuth() {
    const history = useNavigate()
    const [user, setUser] = useState<UserType>()

    useEffect(() => {
        
        const apiCandidatas = api.get('me/me')
        .then((response) => {
            if(response.data.status==="OK"){
                setUser(response.data.dados)
            }else{
                localStorage.clear()
                alert(response.data.msg)
                history('/login')
            }
        })
    }, [])
    return {user}

}