
import '../styles/pedido.scss'
import { useNavigate } from 'react-router-dom'
import { FormEvent,  useEffect,  useState } from 'react'
import { useUserAuth } from '../hooks/useUserAuth'
import api from '../services/api'
import { Header } from '../componentes/Header'
import ReactModal from 'react-modal'
import { Button } from '../componentes/Button'



type PedidosType = {
    id: string
    pagarme_id: string
    quantity: string
    status: string
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
}

ReactModal.setAppElement("#root")
export function Pedido(){
    const history = useNavigate()
    const {user} = useUserAuth()
    const [pedidos, setPedidos] = useState<PedidosType[]>([])
    const [pedidoDetalhe, setPedidoDetalhe] = useState<PedidosType>()
    const [selectedCompra, setSelectedCompra] = useState('pedido');
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function handleChangeCompra(value:string){
        setSelectedCompra(value)
        history('/'+value)
    }

    async function handleAbrePedido(value:string){
        pedidos.forEach(element => {
            if(element.id==value){
                setPedidoDetalhe(element)
            }
        });
        openModal()
    }

    async function handleValidaPix(){
        let id = pedidoDetalhe?.id
        if(id){
            const response = await api.post('pagarme/validapixVoto/', new URLSearchParams({
                id_payment: id
            }))
            .then((response) => {
                alert(response?.data.msg)
                if(response?.data.status){
                    history('/')
                }
            })
            .catch((e) =>{
                if (e.response) {
                    // Is this the correct way?
                    alert(e.response?.data.error)
                }
                
            })
        }
        

    }

    useEffect(() => {

        const carregaDados = async () =>{
            const apiRanking = api.get("pagarme/listCompras")
            .then((response) => {
                //console.log(response.data)
                setPedidos(response.data)
            })
            .catch((e) =>{
                if (e.response) {
                    // Is this the correct way?
                    alert(e.response?.data.error)
                    localStorage.clear()
                    history('/login')
                }
            })
        }
        carregaDados()

    }, [user?.name, user?.email])
    
    return(
        <div id="page-room">
            <ReactModal 
                isOpen={modalIsOpen} 
                contentLabel="Minimal Modal Example"
                style={customStyles}
                >
                <div className='modal-list'>
                    <div className='list-flex'>
                        <label className='head-ranking ranking'>Transação</label>
                        <input 
                            readOnly
                            type='text'
                            placeholder='Nome'
                            value={pedidoDetalhe?.pagarme_id}
                        />
                        <label className='head-ranking ranking'>Votos</label>
                        <input 
                            readOnly
                            type='text'
                            placeholder='Nome'
                            value={pedidoDetalhe?.quantity}
                        />
                        <label className='head-ranking ranking'>Status</label>
                        <input 
                            readOnly
                            type='text'
                            placeholder='Nome'
                            value={pedidoDetalhe?.status}
                        /> 
                        {pedidoDetalhe?.status == "PENDING" &&
                            <Button onClick={handleValidaPix}>Liberar Compra</Button>
                        }
                    </div>
                    <Button onClick={closeModal}>Fechar</Button>
                </div>
                
            </ReactModal>
            <Header />
            <main className='content'>
                
                <div className='room-title'>
                    <h1>Pedidos</h1>
                    <div className='ranking-filter-button'>
                        <select className='filter-select' onChange={event => handleChangeCompra(event.target.value)} value={selectedCompra} >
                            <option value="compra">Compra</option>
                            <option value="pedido">Pedidos</option>
                        </select>
                    </div>
                </div> 
                <div className='question-list'>
                    <div className='list-flex'>
                        <div className='question-table'>
                            <label className='head-ranking ranking'>Transação</label>
                            <label className='head-ranking name'>Quant</label>
                            <label className='head-ranking score'>Status</label>
                        </div>
                        {pedidos.map(pedidosObj => {
                            return(    
                                <div onClick={() => handleAbrePedido(pedidosObj.id)} key={pedidosObj.id} className='question-table'>
                                    <label className='item-ranking ranking'>{pedidosObj.pagarme_id}</label>
                                    <label className='item-ranking name'>{pedidosObj.quantity}</label>
                                    <label className='item-ranking score'>{pedidosObj.status=="PAID"?"Pago":"Em aberto"}</label>
                                </div>
                            )
                        })}
                        
                    </div>
                </div>
            </main>
        </div>
    )
}