
import logoImg from '../assets/img/logo.png'
import { Button } from '../componentes/Button'
import '../styles/home.scss'
import { Candidata } from '../componentes/Candidata'
import { useNavigate } from 'react-router-dom'
import { useCandidata } from '../hooks/useCandidata'
import { FooterCandidataVote } from '../componentes/FooterCandidataVote'
import { Header } from '../componentes/Header'

export function Vote(){
    const history = useNavigate()
    const { candidata  } = useCandidata()
    
    function logout(){
        localStorage.clear()
        history('/login')
    }

    function voltar(){
        history('/')
    }
    
    return(
        <div id="page-room">
            <Header />
            {false && (
                <main className='content'>
                    <div className='room-title'>
                        <Button onClick={voltar}>Home</Button>
                    </div>
                    <div className='question-list'>
                        <div className='list-flex'>
                            <Candidata key={candidata?.id}
                                content={candidata}>
                                    <FooterCandidataVote candidata={candidata} />
                            </Candidata>
                        </div>
                    </div>
                    
                </main>
            )}
            <main className='content'>
                    <div className='room-title'>
                        Acompanhe o Resultado Final do Concurso
                    </div>
                    
            </main>
        </div>
    )
}