
import logoImg from '../assets/img/logo.png'
import { Button } from '../componentes/Button'
import '../styles/valida.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useCandidatas } from '../hooks/useCandidatas'
import { useEffect } from 'react'
import api from '../services/api'

export function Valida(){
    const history = useNavigate()
    const param = useParams()
    
    function logout(){
        localStorage.clear()
        history('/login')
    }

    useEffect(() => {
        const validaDados = async () =>{
            const response = await api.get('valida/id/'+param.id)
            if(response.data.status==="OK"){
                history('/')
            }else{
                alert(response.data.msg)
            }
        }
        validaDados()
    }, [])
    
    return(
        <div id="page-room">
            <header>
                <div className="content">
                    <img src={logoImg} alt="Logo"/>
                    <Button onClick={logout}>Sair</Button>
                </div>
            </header>

            <main className='content'>
                <div className='room-title'>
                    <h1>Compra efetuada com sucesso!{param.id}</h1>
                </div>
            </main>
        </div>
    )
}