import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import api from "../services/api";
import { CandidataType } from "./useCandidatas";



export function useCandidata() {
    const history = useNavigate()
    const params = useParams()
    const [candidata, setCandidata] = useState<CandidataType>()

    useEffect(() => {
        
        const apiCandidatas = api.get('candidatas/candidata/id/'+params.id)
        .then((response) => {
            setCandidata(response.data.dados)
        })
        .catch((e) =>{
            if (e.response) {
                localStorage.clear()
                alert("Sessão Expirada")
                history('/login')
            }
            
        })
    }, [])
    return {candidata}

}