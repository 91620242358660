
import '../styles/compra.scss'
import logoImg from '../assets/img/logo.png'
import { Button } from '../componentes/Button'
import { useNavigate } from 'react-router-dom'
import { FormEvent,  useEffect,  useState } from 'react'
import { useUserAuth } from '../hooks/useUserAuth'
import api from '../services/api'
import InputMask from 'react-input-mask'
import { Header } from '../componentes/Header'
import copy from "copy-to-clipboard";  


export function Compra(){
    const history = useNavigate()
    const {user} = useUserAuth()

    const [quant, setQuant] = useState('2')
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [valor, setValor] = useState('')
    const [valorreal, setValorreal] = useState('10')
    const [cardnumber, setCardNumber] = useState('')
    const [expmonth, setExpmonth] = useState('')
    const [expyear, setExpyear] = useState('')
    const [cvv, setCvv] = useState('')
    const [selectedOption, setSelectedOption] = useState('pix');
    const [selectedCompra, setSelectedCompra] = useState('compra');
    const [showhide, setShowhide] = useState('hidden')
    const [exibeQRCode, setExibeQRCode] = useState('hidden')
    const [exibeForm, setExibeForm] = useState('show')
    const [QRCode, setQRCode] = useState('')
    const [linkPagamento, setLinkPagamento] = useState('')
    const [idPayment, setIdPayment] = useState('')
    
    function logout(){
        localStorage.clear()
        history('/login')
    }

    async function handleCompra(event:FormEvent){
        event.preventDefault()
        if(selectedOption==="pix"){
            const response = await api.post('pagarme/pix/', new URLSearchParams({
                nome: nome,
                email: email,
                valor: valor,
                valorreal: valorreal,
                quant: quant
            }))
            .then((response) => {
                alert(response?.data.msg)
                setExibeQRCode('show')
                setExibeForm('hidden')
                setQRCode(response?.data.qr_code_url)
                setLinkPagamento(response?.data.qr_code)
                setIdPayment(response?.data.id_payment)
            })
            .catch((e) =>{
                if (e.response) {
                    // Is this the correct way?
                    alert(e.response?.data.error)
                }
                
            })

        }else{
            const response = await api.post('pagarme/venda/', new URLSearchParams({
                nome: nome,
                email: email,
                valor: valor,
                valorreal: valorreal,
                quant: quant,
                cardnumber: cardnumber,
                expmonth: expmonth,
                expyear: expyear,
                cvv: cvv
            }))
            .then((response) => {
                alert(response?.data.msg)
                history('/')
            })
            .catch((e) =>{
                if (e.response) {
                    // Is this the correct way?
                    alert(e.response?.data.error)
                }
                
            })
            
        }
        
    }

    async function atualizaValor(event:string){
        setQuant(event)
        
        var arrayQuant = [ "2",  "5",  "8",  "12", "20",  "50"]
        var arrayValue = ["10", "20", "30", "40", "50", "100"]
        
        let index = arrayQuant.indexOf(event)
        let valorTemp = parseFloat(arrayValue[index])
        setValor (valorTemp.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
        setValorreal(arrayValue[index])
    }

    function handleChangePixCard(value:string){
        setSelectedOption(value)
        if(value==="pix"){
            setShowhide('hidden');
        }else{
            setShowhide('show');
        }
    }

    async function handleValidaPix(){
        const response = await api.post('pagarme/validapixVoto/', new URLSearchParams({
            id_payment: idPayment
        }))
        .then((response) => {
            alert(response?.data.msg)
            if(response?.data.status){
                history('/')
            }
        })
        .catch((e) =>{
            if (e.response) {
                // Is this the correct way?
                alert(e.response?.data.error)
            }
            
        })

    }

    async function handleCopiaLink(){
        copy(linkPagamento)
        alert("Código Copiado com sucesso")
    }

    async function handleChangeCompra(value:string){
        setSelectedCompra(value)
        history('/'+value)
    }



    useEffect(() => {

        const carregaDados = async () =>{
            
            if(user?.name){
                setNome(user?.name)
            }
            if(user?.email){
                setEmail(user?.email)
            }
            atualizaValor("2")
        }
        carregaDados()

    }, [user?.name, user?.email])
    
    return(
        <div id="page-room">
            <Header />
            <main className='content'>
                
                <div className='room-title'>
                    <h1>Compra</h1>
                    <div className='ranking-filter-button'>
                        <select className='filter-select' onChange={event => handleChangeCompra(event.target.value)} value={selectedCompra} >
                            <option value="compra">Compra</option>
                            <option value="pedido">Pedidos</option>
                        </select>
                    </div>
                </div> 
                <div className={'compra-list '+exibeForm}>
                    <form onSubmit={handleCompra}>
                        <select className='filter-select' onChange={event => handleChangePixCard(event.target.value)} value={selectedOption} >
                            <option value="pix">PIX</option>
                        </select>
                        <input 
                            readOnly
                            type='text'
                            placeholder='Nome'
                            value={nome}
                        />
                        <input 
                            readOnly
                            type='text'
                            placeholder='E-mail'
                            value={email}
                        />
                        <InputMask
                            className={showhide}
                            name="cardnumber"
                            mask="9999 9999 9999 9999"
                            value={cardnumber}
                            onChange={event => setCardNumber(event.target.value)}
                            placeholder='Cartão de Crédito'
                            alwaysShowMask = {false}
                        />
                        <InputMask
                            className={showhide}
                            name="expmonth"
                            mask="99"
                            value={expmonth}
                            onChange={event => setExpmonth(event.target.value)}
                            placeholder='Mês Expiração'
                            alwaysShowMask = {false}
                        />
                        <InputMask
                            className={showhide}
                            name="expyear"
                            mask="99"
                            value={expyear}
                            onChange={event => setExpyear(event.target.value)}
                            placeholder='Ano Expiração'
                            alwaysShowMask = {false}
                        />
                        <InputMask
                            className={showhide}
                            name="cvv"
                            mask="999"
                            value={cvv}
                            onChange={event => setCvv(event.target.value)}
                            placeholder='CVV'
                            alwaysShowMask = {false}
                        />
                        
                        <select name="quant" value={quant} onChange={event => atualizaValor(event.target.value)} > 
                            <option value="2">2 Votos</option>
                            <option value="5">5 Votos</option>
                            <option value="8">8 Votos</option>
                            <option value="12">12 Votos</option>
                            <option value="20">20 Votos</option>
                            <option value="50">50 Votos</option>
                        </select>

                        <input 
                            readOnly
                            type='text'
                            placeholder='Valor'
                            value={valor}
                        />
                        
                        <Button>Comprar</Button>
                    </form>
                
                </div>
                <div className={'compra-list '+exibeQRCode}>
                    <h3>Leia o QRCode ou copie o link de pagamento</h3>
                    <img src={QRCode} />
                    <label>{linkPagamento}</label>
                    <Button onClick={handleCopiaLink}>Copiar Código PIX</Button>
                    <div className="separator">Já efetuei o Pagamento</div>
                    <Button onClick={handleValidaPix}>Liberar compra</Button>
                </div>
            </main>
        </div>
    )
}